import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';
const isApk = environment.isApk;

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
    private breakpoints = {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536
    };

    public isApk: boolean = isApk;

    private currentBreakpoint = new BehaviorSubject<Breakpoint>(this.getCurrentBreakpoint());

    public readonly is = {
        get xs() { return this.parent.getCurrentBreakpoint() === 'xs'; },
        get sm() { return this.parent.getCurrentBreakpoint() === 'sm'; },
        get md() { return this.parent.getCurrentBreakpoint() === 'md'; },
        get lg() { return this.parent.getCurrentBreakpoint() === 'lg'; },
        get xl() { return this.parent.getCurrentBreakpoint() === 'xl'; },
        get '2xl'() { return this.parent.getCurrentBreakpoint() === '2xl'; },
        parent: this
    };

    constructor() {
        fromEvent(window, 'resize')
        .pipe(distinctUntilChanged())
        .subscribe(() => {
            this.currentBreakpoint.next(this.getCurrentBreakpoint());
        });
    }

    public breakpoint$(): Observable<Breakpoint> {
        return this.currentBreakpoint.asObservable();
    }

    public getCurrentBreakpoint(): Breakpoint {
        const width = window.innerWidth;

        if (width < this.breakpoints.sm) return 'xs';
        if (width < this.breakpoints.md) return 'sm';
        if (width < this.breakpoints.lg) return 'md';
        if (width < this.breakpoints.xl) return 'lg';
        if (width < this.breakpoints['2xl']) return 'xl';
        return '2xl';
    }

    public isGreaterEqual(breakpoint: Breakpoint): boolean {
        const breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];
        const currentIndex = breakpoints.indexOf(this.getCurrentBreakpoint());
        const checkIndex = breakpoints.indexOf(breakpoint);
        return currentIndex >= checkIndex;
    }
    
    public isLessEqual(breakpoint: Breakpoint): boolean {
        const breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl'];
        const currentIndex = breakpoints.indexOf(this.getCurrentBreakpoint());
        const checkIndex = breakpoints.indexOf(breakpoint);
        return currentIndex <= checkIndex;
    }
}